/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable max-len */
import room1 from 'assets/hotels/hotel-1.jpg';
import room2 from 'assets/hotels/hotel-2.jpg';
import room3 from 'assets/hotels/hotel-3.jpg';
import room4 from 'assets/hotels/hotel-4.jpg';
import room5 from 'assets/hotels/hotel-5.jpg';
import room6 from 'assets/hotels/hotel-6.jpg';
import room7 from 'assets/hotels/hotel-7.jpg';

import { bookingOptionsIconsStyle } from 'styles/mui/containers/homepage-styles';

import {
  LocationCityOutlined,
  AirplanemodeActive,
  DirectionsCarFilled,
  RestaurantMenu,
  Place, Email, Phone } from '@mui/icons-material';

export const navPagesCommonData = [
  {
    title: 'Home',
    path: '/',
  },
  {
    title: 'About',
    path: '/about',
  },
];

export const navPrivateLinksData = [
  {
    title: 'My Bookings',
    path: '/my-bookings',
  },
  {
    title: 'Customer Service',
    path: '/customer-service',
  },
];

export const navAuthLinksData = [
  {
    title: 'Contact',
    path: '/contact',
  },
  {
    title: 'Membership',
    path: '/auth/membership-plans',
    isLogin: false,
  },
];

export const navAuthLoginSignupLinksData = [
  {
    title: 'Login',
    path: '/auth/login',
    isLogin: true,
  },
  {
    title: 'Signup',
    path: '/auth/signup',
    isLogin: false,
  },
];

export const luxuryRoomsSectionImages = [room1, room2, room3, room4, room5, room6, room7];

export const recommendedHotelsData = [room1, room2, room3, room4, room5, room6, room7];

export const footerLinksData = [
  {
    label: 'About',
    path: '/about',
  },
  {
    label: 'Contact',
    path: '/contact',
    isAuth: true,
  },
  {
    label: 'Membership',
    path: '/auth/membership-plans',
    isAuth: true,
  },
  {
    label: 'Privacy Policy',
    path: '/privacy-policy',
  },
  {
    label: 'Terms & Conditions',
    path: '/terms-and-conditions',
  },
];

export const footerContactLinksData = [
  {
    icon: <Place sx={{ color: 'primary.main' }} fontSize="small" />,
    label: 'Office # OF-01, Golden Mile 6, Palm Jumeirah, Dubai, UAE',
  },
  {
    icon: <Phone sx={{ color: 'primary.main' }} fontSize="small" />,
    label: '+971507526499',
  },
  {
    icon: <Email sx={{ color: 'primary.main' }} fontSize="small" />,
    label: 'info@sublime.com',
  },
];

export const testimonialsData = [
  {
    name: 'John Doe',
    designation: 'CEO',
    rating: 5,
    review:
      'Using Sublime to book my luxury hotel stay was a great experience. It made the entire booking procedure seamless. I can recommend it to anyone seeking to experience a luxury.',
  },
  {
    name: 'Jessie Pinkman',
    designation: 'Designer',
    rating: 4,
    review:
      'With the Sublime platform, I was able to indulge in the ultimate luxury hotel experience without any stress. The user-friendly interface and wide range of options made it easy.',
  },
  {
    name: 'John Sparrow',
    designation: 'Marketing Manager',
    rating: 4.5,
    review:
      "I can't imagine planning my luxury hotel getaway without Sublime. It simplified everything from finding the perfect hotel to booking additional services. It truly enhanced my overall experience.",
  },
];

export const legacyOfHospitalityListItems = [
  'Serving Members with Unwavering Dedication',
  'Rooted in Values of Attentiveness & Personalized Care',
  'Exemplary Services and Attention to Detail',
  'Uphold a Tradition of Perfection in Hospitality',
];

export const faqsData = [
  {
    title: 'How do I search for luxury hotels on the Sublime Booking Application?',
    description:
      'To search for luxury hotels, you can simply select your destination following the duration of your stay, and for a more personalized search enter specific requirements or amenities in the search bar. The application will then show the collection of luxurious hotels based on your search.',
  },
  {
    title: 'Can I cancel or modify my reservation through the application?',
    description:
      "Yes, Sublime lets you cancel or modify your reservation following the defined policies of the hotel you've booked. The modification process is simple, just search for your reservation and follow the prompts to cancel or modify your reservation.",
  },
  {
    title: 'What amenities & services are available via Sublime in hotel bookings?',
    description:
      'A range of exquisite services and amenities are available through the Sublime application to ensure an extraordinary experience tailored to your preferences. Moreover, the offered amenities and services may vary depending on the chosen hotel.',
  },
  {
    title: 'How can I sign up for my membership on the Sublime booking application?',
    description:
      "The process for signing up on Sublime is very simple. Just head to the “Sign-up” section and create your account by providing the basic information such as your name, email, and password. After completing the registration process, you'll be able to access exclusive membership benefits to avail better services with the Sublime.",
  },
  {
    title: 'Does Sublime provide customer support to assist with the booking process?',
    description:
      'Through the Sublime booking application platform, you can reach out to the customer support team regarding any support during the booking process. The general methods to contact the help desk are via phone, email, or WhatsApp chat support directly through the application.',
  },
];

export const citiesData = [
  {
    id: 1,
    isHotel: false,
    primary: 'madrid',
    secondary: 'madrid',
  },
  {
    id: 2,
    isHotel: false,
    primary: 'riyadh',
    secondary: 'riyadh',
  },
  {
    id: 3,
    isHotel: false,
    primary: 'london',
    secondary: 'london',
  },
  {
    id: 4,
    isHotel: false,
    primary: 'paris',
    secondary: 'paris',
  },
  {
    id: 5,
    isHotel: false,
    primary: 'los angeles',
    secondary: 'los angeles',
  },
  {
    id: 6,
    isHotel: false,
    primary: 'new york',
    secondary: 'new york',
  },
  {
    id: 7,
    isHotel: false,
    primary: 'milan',
    secondary: 'milan',
  },
  {
    id: 8,
    isHotel: false,
    primary: 'barcelona',
    secondary: 'barcelona',
  },
  {
    id: 9,
    isHotel: false,
    primary: 'zurich',
    secondary: 'zurich',
  },
  {
    id: 10,
    isHotel: false,
    primary: 'athens',
    secondary: 'athens',
  },
  {
    id: 11,
    isHotel: false,
    primary: 'dubai',
    secondary: 'dubai',
  },
];

export const bookingTabsOptions = [
  {
    id: 1,
    label: 'Hotels',
    value: 'hotel',
    link: '/home',
    icon: <LocationCityOutlined sx={bookingOptionsIconsStyle} />,
    url: '/hotels-booking/bookings/'
  },
  {
    id: 2,
    label: 'Private Jet',
    value: 'private_jet',
    link: '/private-jets',
    url: '/privatejet/jet-bookings/',
    icon: (
      <AirplanemodeActive
        fontSize="medium"
        sx={{ ...bookingOptionsIconsStyle, transform: 'rotate(45deg)' }}
      />
    ),
  },
  {
    id: 3,
    label: 'Limousine',
    value: 'limousine',
    link: '/limousine',
    url: '/cars-booking/limousine-bookings/',
    icon: <DirectionsCarFilled fontSize="medium" sx={bookingOptionsIconsStyle} />,
  },
  {
    id: 4,
    label: 'Restaurant',
    value: 'restaurant',
    link: '/restaurants',
    url: '/restaurant/restaurant-booking/',
    icon: <RestaurantMenu fontSize="medium" sx={bookingOptionsIconsStyle} />,
  },
];

export const tabIndicatorSettings = {
  display: {
    xs: 'none',
    md: 'inherit',
  },
};
