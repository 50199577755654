/* eslint-disable no-unused-vars */
import React from 'react';
import propTypes from 'prop-types';
// MUI COMPONENTS && ICONS
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';

import { useReadNotificationMutation } from 'services/private/notifications';
import { useNavigate } from 'react-router-dom';

function Notification({
  timeFromNow,
  handleSetNotifications,
  handleClose,
  notification,
}) {
  const navigate = useNavigate();
  // API HOOKS
  const [readNotification] = useReadNotificationMutation();

  // CONSTANTS

  const isRead = !notification?.notification_is_read;
  const isDescriptionLarge = notification?.description?.length > 20;
  const description = isDescriptionLarge
    ? `${notification?.description.substring(0, 28)}...`
    : notification?.description;
  const isGigTitleLarge = notification?.gig_title?.length > 16;
  const footerText = isGigTitleLarge
    ? `${notification?.gig_title.substring(0, 16)}...`
    : notification?.gig_title;

  const handleNavigate = async notificationResponseData => {
    if (notificationResponseData?.room_id) navigate(`customer-service/chat?room=${notificationResponseData?.room_id}`);
  };

  const handleReadNotifications = async () => {
    const res = await readNotification({ id: notification?.id, isRead: true });
    if (res?.data) {
      handleSetNotifications();
      handleNavigate(res?.data);
      handleClose();
    }
  };

  return (
    <MenuItem
      divider
      className="py-2"
      style={isRead ? { backgroundColor: '#F2F2F2', color: 'black' } : {}}
      onClick={handleReadNotifications}
    >
      <Box className="w-100">
        <Typography variant="body2" fontSize="12px">
          {timeFromNow}
        </Typography>
        <Box className="d-flex align-items-center gap-4 mt-1">
          <Avatar src={notification?.sender_image} />
          <Box>
            <Box className="d-flex gap-1 flex-wrap align-items-center">
              <Typography variant="body1">
                {`${notification?.sender_first_name} ${notification?.sender_last_name}`}
              </Typography>

              <Typography variant="body1">{notification?.heading}</Typography>
            </Box>
            <Box>
              <Typography variant="body2">{description}</Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </MenuItem>
  );
}

Notification.propTypes = {
  timeFromNow: propTypes.string,
  notification: propTypes.object,
  handleSetNotifications: propTypes.func.isRequired,
  handleClose: propTypes.func.isRequired,
};
Notification.defaultProps = {
  timeFromNow: '',
  notification: {},
};
export default Notification;
