import React from 'react';
import Box from '@mui/material/Box';
import { Outlet, useLocation } from 'react-router-dom';

// COMPONENTS & CUSTOM HOOKS & UTILITIES
import useScrollToTop from 'customHooks/useScrollToTop';
// import styles from 'styles/common/layout.module.scss';
// import whatsappIcon from 'assets/whatsapp-icon.webp';
import Navbar from './components/Navbar';
import Footer from './components/Footer';

function LayoutWrapper() {
  useScrollToTop();
  const location = useLocation();

  // Define routes where Navbar & Footer should be hidden
  const hiddenRoutes = ['/privacy-policy', '/terms-and-conditions', '/sitemap'];
  const shouldHideLayout = hiddenRoutes.includes(location.pathname);

  return (
    <Box display="flex" flexDirection="column" minHeight="100vh">
      {!shouldHideLayout && <Navbar />}

      <Box className="flex-fill">
        <Outlet />
      </Box>
      {!shouldHideLayout && <Footer />}

      {/* <Box className={styles.whatsappIconBox}>
        <img src={whatsappIcon} alt="Whatsapp" />
      </Box> */}
    </Box>
  );
}

export default LayoutWrapper;
