import React, { memo, useCallback, useMemo, useState } from 'react';
import { Box, FormLabel, IconButton, TextField } from '@mui/material';
import propTypes from 'prop-types';
import { useField } from 'formik';
import { Search, Visibility, VisibilityOff } from '@mui/icons-material';
import { searchFieldsStyle } from 'styles/mui/common/searchFields';
import { capitalizeWords } from 'utilities/helpers';

function FormikField({
  variant,
  fieldLabel,
  name,
  disabled,
  type,
  className,
  multiline,
  maxRows,
  minRows,
  label,
  isSearch,
  color,
  isAutoComplete,
  isRequired,
  placeholder,
  size,
  fullWidth,
  icon,
  onBlur,
}) {
  const [isVisible, setVisible] = useState(false);
  const [field, meta] = useField(name || '');
  const { value, onChange } = field;
  const { error, touched } = meta;

  const toggleVisibility = () => {
    setVisible(prevState => !prevState);
  };

  const handleChange = useCallback(
    e => {
      onChange(e);
    },
    [onChange]
  );

  const isError = useMemo(() => !!(error && touched), [error, touched]);
  const isPassword = useMemo(() => type === 'password', [type]);
  const passwordIcon = useMemo(() => {
    if (isPassword) {
      return (
        <IconButton onClick={toggleVisibility}>
          {isVisible ? <VisibilityOff /> : <Visibility />}
        </IconButton>
      );
    }

    if (isSearch) {
      return (
        icon || (
          <Search className="mb-4" fontSize="small" sx={{ color: 'grayColor.main' }} />
        )
      );
    }

    return undefined;
  }, [isVisible, isPassword]);
  const inputType = useMemo(() => {
    if (isPassword) {
      if (isVisible) {
        return 'text';
      }

      return 'password';
    }

    return type;
  }, [isPassword, isVisible, type]);

  return (
    <Box className="w-100">
      {label && (
        <FormLabel className={`${isRequired ? 'required' : ''} mb-2`}>{label}</FormLabel>
      )}

      <TextField
        autoComplete={!isAutoComplete ? 'off' : undefined}
        multiline={multiline}
        className={className}
        name={name}
        variant={variant}
        color={color}
        label={fieldLabel}
        value={isSearch ? capitalizeWords(value) : value}
        onChange={handleChange}
        onBlur={onBlur}
        error={isError}
        disabled={disabled}
        helperText={!isSearch && isError ? error : ''}
        type={inputType}
        placeholder={placeholder}
        fullWidth={fullWidth}
        size={size}
        InputProps={{
          endAdornment: passwordIcon,
          disableUnderline: isSearch,
        }}
        InputLabelProps={{ className: isRequired ? 'required' : '', shrink: true }}
        minRows={minRows}
        maxRows={maxRows}
        sx={isSearch && searchFieldsStyle(color, isError)}
      />
    </Box>
  );
}

FormikField.propTypes = {
  name: propTypes.string.isRequired,
  variant: propTypes.string,
  color: propTypes.string,
  className: propTypes.string,
  fieldLabel: propTypes.string,
  disabled: propTypes.bool,
  type: propTypes.string,
  multiline: propTypes.bool,
  maxRows: propTypes.number,
  minRows: propTypes.number,
  label: propTypes.string,
  isSearch: propTypes.bool,
  isAutoComplete: propTypes.bool,
  isRequired: propTypes.bool,
  placeholder: propTypes.string,
  size: propTypes.string,
  onBlur: propTypes.func,
  fullWidth: propTypes.bool,
  icon: propTypes.element,
};

FormikField.defaultProps = {
  variant: 'filled',
  color: 'primary',
  className: '',
  fieldLabel: undefined,
  disabled: false,
  type: 'text',
  multiline: false,
  maxRows: 4,
  minRows: 4,
  label: '',
  isSearch: false,
  isAutoComplete: false,
  isRequired: false,
  placeholder: '',
  size: '',
  fullWidth: true,
  onBlur: () => {},
  icon: undefined,
};

export default memo(FormikField);
