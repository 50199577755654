export const searchFieldsStyle = (color, isError, height) => ({
  '& .MuiFilledInput-root': {
    overflow: 'hidden',
    backgroundColor:
      color === 'whiteColor' ? 'transparent !important' : '#fff !important',
    border: '1px solid',
    borderColor: isError ? '#D32F2F' : '#D9D9D9',
    borderBottomLeftRadius: '4px',
    borderBottomRightRadius: '4px',
    height: height || '',
  },
  '&:hover': {
    backgroundColor:
      color === 'whiteColor' ? 'transparent !important' : '#fff !important',
    borderColor: color,
  },
  '&.Mui-focused': {
    backgroundColor:
      color === 'whiteColor' ? 'transparent !important' : '#fff !important',
    boxShadow: '0',
    borderColor: color,
  },
  '& .MuiIconButton-root': {
    '&:hover': {
      backgroundColor: 'transparent',
      cursor: 'inherit'
    },
    '& .MuiTouchRipple-root': {
      display: 'none',
    },
  },
  input: {
    '&::placeholder': {
      textOverflow: 'ellipsis !important',
      color: 'blackColor.main !important',
      fontSize: '14px',
      fontWeight: 500,
    },
  },
});

export const heightStyle = height => ({
  '& .MuiOutlinedInput-input': {
    height: height && `${height} !important`,
  },
});
