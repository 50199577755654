import { Badge, IconButton } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { Notifications as NotificationsIcon } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import {
  useLazyGetNotificationQuery,
  useReadAllNotificationsMutation,
} from 'services/private/notifications';
import useConnectWebSocket from 'customHooks/useConnectWebSocket';
import { findUnreadNotificationCount } from 'utilities/helpers';
import { getNotificationUrl } from 'containers/pages/chat/utilities/sockets-urls';
import useGetMenuHandlers from 'customHooks/useGetMenuHandlers';
import { privateAPI } from 'services/private';
import NotificationsMenu from './NotificationsMenu';

function Notifications() {
  const dispatch = useDispatch();
  const [notificationMenu, handleOpenNotificationMenu, handleCloseNotificationMenu] =
    useGetMenuHandlers();

  // STATE HOOKS
  const [limit, setLimit] = useState(10);
  const [notifications, setNotifications] = useState([]);
  const [unreadNotifications, setUnreadNotifications] = useState(null);

  // API HOOKS
  const [getNotifications] = useLazyGetNotificationQuery();
  const [readAllNotifications, { isLoading }] = useReadAllNotificationsMutation();
  const disable = limit === notifications?.count;
  // REFS
  const notificationStateRef = useRef(notifications);
  const notificationCountRef = useRef(null);
  // const notificationContainerRef = useRef(null);

  const socket = useConnectWebSocket(getNotificationUrl());
  const userInfo = useSelector(state => state.auth.user.profile);

  const handleSetNotifications = data => {
    notificationStateRef.current = data;
    setNotifications(data);
    setUnreadNotifications(findUnreadNotificationCount(data));
  };
  const handleGetMoreData = () => {
    setLimit(limit + 10);
  };

  // WEB-SOCKET
  useEffect(() => {
    if (socket) {
      socket.onmessage = e => {
        const data = JSON.parse(e.data);
        const isMineNotification = data.receiver === userInfo?.id;
        if (isMineNotification) {
          const updatedNotificationsList = [data, ...notificationStateRef.current];
          handleSetNotifications(updatedNotificationsList);
        }
        dispatch(privateAPI?.util?.invalidateTags(['CreateReport']));
      };
    }
  }, [socket]);

  useEffect(() => {
    const handleGetInitialNotifications = async () => {
      const res = await getNotifications({ limit }, { skip: !limit });
      notificationCountRef.current = res?.data?.results.length;
      handleSetNotifications(res?.data?.results);
    };

    handleGetInitialNotifications();
  }, [limit]);

  // HANDLERS
  const handleReadAllNotifications = async () => {
    const resp = await readAllNotifications();
    if (resp?.data) {
      const getNotificationsResp = await getNotifications({ limit });
      handleSetNotifications(getNotificationsResp?.data?.results);
    }
  };
  // HANDLER FUNCTIONS
  const handleGetNotifications = async () => {
    const res = await getNotifications({ limit });
    notificationCountRef.current = res?.results?.length;
    handleSetNotifications(res?.data?.results);
  };

  return (
    <>
      <IconButton onClick={handleOpenNotificationMenu}>
        <Badge color="warning" badgeContent={unreadNotifications}>
          <NotificationsIcon sx={{ color: 'white' }} />
        </Badge>
      </IconButton>
      <NotificationsMenu
        anchorEl={notificationMenu}
        handleClose={handleCloseNotificationMenu}
        notifications={notifications}
        handleReadAll={handleReadAllNotifications}
        handleSetNotifications={handleGetNotifications}
        isLoading={isLoading}
        handleGetMoreData={handleGetMoreData}
        disable={disable}
      />
    </>
  );
}

export default Notifications;
