/* eslint-disable no-nested-ternary */
import React, { useCallback, useMemo, useState } from 'react';
// MUI COMPONENTS && ICONS
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuList from '@mui/material/MenuList';
// import Modal from '@mui/material/Modal';
import Divider from '@mui/material/Divider';
import Tooltip from '@mui/material/Tooltip';
// MUI COMPONENTS && ICONS
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Person4Icon from '@mui/icons-material/Person4';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
// import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import MenuIcon from '@mui/icons-material/Menu';
import LiveHelpOutlinedIcon from '@mui/icons-material/LiveHelpOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import HelpIcon from '@mui/icons-material/Help';

import { Link, useNavigate } from 'react-router-dom';

import styles from 'styles/common/layout.module.scss';
// import { loginModalStyles } from 'styles/mui/common/modal-styles';
import logoImg from 'assets/logo.png';
// import logoBlack from 'assets/Logo-Black.png';
import {
  navAuthLinksData,
  navAuthLoginSignupLinksData,
  navPagesCommonData,
  navPrivateLinksData,
} from 'utilities/data';
import useAuth from 'customHooks/useAuth';

import {
  navbarMenuStyles,
  navbarProfileMenuStyles,
} from 'styles/mui/common/layoutStyles';
import { navMenuPositionProps } from 'utilities/helperProps';

// import Login from 'containers/pages/auth/login';
import Notifications from 'containers/common/components/Notifications';

function Navbar() {
  const navigate = useNavigate();
  // const [loginModalOpen, setLoginModalOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isProfileMenuOpen, setIsProfileMenuOpen] = useState(null);
  const [isHelpMenuOpen, setIsHelpMenuOpen] = useState(null);
  const { isAuthenticated, userMembership, userProfile } = useAuth();
  const { handleLogout } = useAuth();
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('md'));

  const toggleMenu = useCallback(() => {
    setIsMenuOpen(prevState => !prevState);
  }, [isMenuOpen]);

  const toggleLoginModalOpen = useCallback(() => {
    navigate('auth/login');
  });

  const menuCondition = useMemo(() => {
    if (isMobile) {
      return isMenuOpen ? 'd-flex flex-column' : 'd-none';
    }

    return 'd-flex';
  }, [isMenuOpen, isMobile]);

  const handleClick = event => {
    setIsProfileMenuOpen(event.currentTarget);
  };
  const handleClose = () => {
    setIsProfileMenuOpen(null);
  };

  const handleOpenHelpMenu = event => {
    setIsHelpMenuOpen(event.currentTarget);
  };
  const handleCloseHelpMenu = () => {
    setIsHelpMenuOpen(null);
  };

  return (
    <Box sx={{ background: '#191919' }} position="sticky" top={0} zIndex={1000}>
      <Box className="py-2 px-3 px-lg-5 d-flex align-items-center justify-content-between gap-4 position-relative">
        <Box className={styles.logoContainer}>
          <Link to="/">
            <img src={logoImg} alt="Logo" />
          </Link>
        </Box>

        <IconButton className="d-flex d-md-none" onClick={toggleMenu}>
          <MenuIcon className="text-white" />
        </IconButton>

        <Box
          className={`${menuCondition} align-items-center gap-4`}
          sx={navbarMenuStyles(isMenuOpen)}
        >
          {navPagesCommonData.map((page, index) => (
            <Link
              key={index}
              to={page.path}
              className="resetLink"
              onClick={isMobile ? toggleMenu : undefined}
            >
              <Typography variant="body1" color="white" fontWeight={400}>
                {page.title}
              </Typography>
            </Link>
          ))}
          {isAuthenticated &&
            navPrivateLinksData.map((link, index) => (
              <Link
                key={index}
                to={link.path}
                className="resetLink"
                onClick={isMobile ? toggleMenu : undefined}
              >
                <Typography variant="body1" color="white" fontWeight={400}>
                  {link.title}
                </Typography>
              </Link>
            ))}

          {!isAuthenticated &&
            navAuthLinksData.map((link, index) => (link.isLogin ? (
              <Typography
                key={index}
                onClick={toggleLoginModalOpen}
                variant="body1"
                color="white"
                className="pointer"
                fontWeight={400}
              >
                {link.title}
              </Typography>
            ) : (
              <Link
                key={index}
                to={link.path}
                className="resetLink"
                onClick={isMobile ? toggleMenu : undefined}
              >
                <Typography variant="body1" color="white" fontWeight={400}>
                  {link.title}
                </Typography>
              </Link>
            )))}

          {!isMobile && !isAuthenticated && (
            <Divider
              orientation="vertical"
              variant="middle"
              sx={{
                height: '25px',
                bgcolor: '#ffffff',
                border: '1px solid #ffffff',
              }}
              flexItem
            />
          )}

          {!isAuthenticated &&
            navAuthLoginSignupLinksData.map((link, index) => (link.isLogin ? (
              <Typography
                key={index}
                onClick={toggleLoginModalOpen}
                variant="body1"
                color="white"
                className="pointer"
                fontWeight={400}
              >
                {link.title}
              </Typography>
            ) : (
              <Link
                key={index}
                to={link.path}
                className="resetLink"
                onClick={isMobile ? toggleMenu : undefined}
              >
                <Typography variant="body1" color="white" fontWeight={400}>
                  {link.title}
                </Typography>
              </Link>
            )))}
          {isAuthenticated && (
            <Box className="d-flex align-items-center gap-2">
              <Box className="pointer" onClick={handleOpenHelpMenu}>
                <Tooltip title="Get Help">
                  <HelpIcon sx={{ color: 'white' }} />
                </Tooltip>
              </Box>
              <Notifications />
              <Avatar
                alt={userProfile?.first_name}
                src={userProfile?.image}
                sx={{ width: 58, height: 58 }}
              />
              <Box className="d-flex gap-1 pointer" onClick={handleClick}>
                <Box>
                  <Typography
                    color="white"
                    variant="body2"
                    fontSize="16px"
                    fontWeight={500}
                  >
                    {userProfile?.first_name} {userProfile?.last_name}
                  </Typography>
                  <Typography color="white" variant="body2">
                    {userMembership?.membership_name} Member
                  </Typography>
                </Box>
                <KeyboardArrowDownIcon sx={{ color: 'white' }} />
              </Box>
            </Box>
          )}
        </Box>
        <Menu
          key={isProfileMenuOpen}
          open={!!isProfileMenuOpen}
          anchorEl={isProfileMenuOpen}
          onClose={handleClose}
          {...navMenuPositionProps}
          sx={{ ...navbarProfileMenuStyles, mt: 3 }}
        >
          <MenuList sx={{ outline: 'none', border: 'none' }} disablePadding>
            <MenuItem
              dense
              onClick={() => {
                if (isMobile) toggleMenu();
                navigate('profile');
                handleClose();
              }}
            >
              <ListItemIcon>
                <Person4Icon fontSize="small" />
              </ListItemIcon>
              <ListItemText fontSize="12px">My Profile</ListItemText>
            </MenuItem>

            <MenuItem
              dense
              onClick={() => {
                handleLogout();

                if (isMobile) toggleMenu();
              }}
            >
              <ListItemIcon>
                <PowerSettingsNewIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Logout</ListItemText>
            </MenuItem>
          </MenuList>
        </Menu>
        <Menu
          key={isHelpMenuOpen}
          open={!!isHelpMenuOpen}
          anchorEl={isHelpMenuOpen}
          onClose={handleCloseHelpMenu}
          {...navMenuPositionProps}
          sx={{ ...navbarProfileMenuStyles, mt: 4 }}
        >
          <MenuList sx={{ outline: 'none', border: 'none' }} disablePadding>
            <MenuItem
              dense
              onClick={() => {
                if (isMobile) toggleMenu();
                navigate('customer-service');
                handleCloseHelpMenu();
              }}
            >
              <ListItemIcon>
                <SupportAgentIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText fontSize="12px">Customer Support</ListItemText>
            </MenuItem>
            <MenuItem
              dense
              onClick={() => {
                if (isMobile) toggleMenu();
                handleCloseHelpMenu();
              }}
            >
              <ListItemIcon>
                <LiveHelpOutlinedIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText fontSize="12px">FAQ</ListItemText>
            </MenuItem>
            <MenuItem
              dense
              onClick={() => {
                if (isMobile) toggleMenu();
                handleCloseHelpMenu();
              }}
            >
              <ListItemIcon>
                <InfoOutlinedIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText fontSize="12px">User Guide</ListItemText>
            </MenuItem>
          </MenuList>
        </Menu>
        {/* <Modal open={loginModalOpen} onClose={toggleLoginModalOpen}>
          <Box sx={loginModalStyles}>
            <CancelPresentationIcon className="pointer" onClick={toggleLoginModalOpen} />
            <Box className="d-flex justify-content-center align-items-center">
              <img src={logoBlack} alt="Logo" width="50%" />
            </Box>
            <Login onClose={toggleLoginModalOpen} />
          </Box>
        </Modal> */}
      </Box>
    </Box>
  );
}

export default Navbar;
