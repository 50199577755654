const { createTheme } = require('@mui/material');

// CUSTOMIZE MUI THEME HERE!
const theme = createTheme({
  typography: {
    allVariants: {
      fontFamily: 'Poppins, sans-serif',
    },

    pageTitle: {
      fontFamily: 'Poppins, sans-serif',
      fontSize: '24px',
      fontWeight: 500,
      marginBottom: '10px',
    },
    title: {
      fontFamily: 'Kalnia, sans-serif',
      fontSize: '40px',
      fontWeight: 400,
      marginBottom: '10px',

      '@media screen and (max-width: 768px)': {
        fontSize: '36px',
      },
    },
    description: {
      fontFamily: 'Poppins, sans-serif',
      fontSize: '18px',
      fontWeight: 400,
      marginBottom: '10px',
    },
  },

  breakpoints: {
    values: {
      xs: 0,
      sm: 576,
      md: 768,
      lg: 992,
      xl: 1200,
      xxl: 1400,
    },
  },

  palette: {
    primary: {
      main: '#D4B35B',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#323232',
      contrastText: '#ffffff',
    },
    silverColor: {
      main: '#DCDDDE',
      contrastText: '#000',
    },
    whiteColor: {
      main: '#ffffff',
    },
    grayColor: {
      main: '#7C7C7C',
      bright: '#EAEAEA',
      smoke: '#F1F1F1'
    },
    blueColor: {
      main: '#17A1FA',
      lightBlue: '#DFF2FF',
    },
    parrot: {
      main: '#50CD89',
    },
    blackColor: {
      main: '#000000',
    },
  },

  components: {
    MuiTextField: {
      variants: [
        {
          props: { color: 'whiteColor' },
          style: {
            '& .MuiFormLabel-root, & input, & .MuiSvgIcon-root': {
              color: 'white !important',
            },

            '& fieldset': {
              borderColor: 'white !important',
            },
          },
        },
      ],
    },

    MuiContainer: {
      defaultProps: {
        fixed: false,
        maxWidth: 'xl',
      },
    },

    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: 'rgba(0, 0, 0, 0.5)',
        },
      },
    },

    MuiTypography: {
      defaultProps: {
        variantMapping: {
          pageTitle: 'h1',
          title: 'h1',
          description: 'p',
        },
      },
    },

    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'capitalize',
        },
        file: {
          background: '#fbf9fa',
          fontWeight: '600',
          width: '100%',
          padding: '10px 20px',
          textTransform: 'capitalize',
          color: '#422438',
        },
      },
    },
  },
});

export default theme;
