/* eslint-disable react/require-default-props */
import React from 'react';
import propTypes from 'prop-types';
// MUI COMPONENTS && ICONS
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Fade from '@mui/material/Fade';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { notificationMenuStyles } from 'styles/mui/common/layoutStyles';
import { fromNowTime } from 'utilities/helpers';
import Notification from './Notification';

function NotificationsMenu({
  disable,
  anchorEl,
  handleClose,
  handleReadAll,
  handleSetNotifications,
  notifications = [],
  isLoading,
  handleGetMoreData,
}) {
  return (
    <Menu
      key={anchorEl}
      anchorEl={anchorEl}
      open={!!anchorEl}
      onClose={handleClose}
      sx={notificationMenuStyles}
      TransitionComponent={Fade}
      disableScrollLock
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <Box className="d-flex align-items-end justify-content-between py-2 px-3">
        <Typography className=" font-semibold">Recent Notifications</Typography>
        <Button
          size="small"
          className="px-2 py-1 text-sm"
          variant="text"
          color="primary"
          disabled={notifications?.length === 0}
          onClick={handleReadAll}
        >
          Read All
        </Button>
      </Box>
      <Divider sx={{ borderColor: 'gray' }} />
      <MenuList
        sx={{
          outline: 'none',
          border: 'none',
          paddingBottom: '0px',
          maxHeight: '450px',
          overflowY: 'auto',
          '::-webkit-scrollbar': { width: '5px' },
          '::-webkit-scrollbar-thumb': {
            height: '25%',
            background: '#b3b3b3',
            borderRadius: '5px',
          },
        }}
        disablePadding
      >
        {!isLoading &&
          notifications?.length > 0 &&
          notifications?.map(notification => {
            const timeFromNow = fromNowTime(notification?.notification_created_at);
            return (
              <Notification
                key={notification?.id}
                handleSetNotifications={handleSetNotifications}
                handleClose={handleClose}
                notification={notification}
                timeFromNow={timeFromNow}
              />
            );
          })}
        {isLoading && (
          <Stack>
            {Array.from({ length: 4 }, (_, index) => (
              <MenuItem key={index} divider className="py-3">
                <Box className="w-100">
                  <Skeleton
                    variant="text"
                    width="40%"
                    animation="wave"
                    sx={{ fontSize: '1rem' }}
                  />
                  <Box className="d-flex justify-content-between align-items-center gap-4">
                    <Skeleton
                      variant="circular"
                      animation="wave"
                      width={50}
                      height={40}
                    />
                    <Skeleton
                      variant="rectangular"
                      animation="wave"
                      width="100%"
                      height={50}
                    />
                  </Box>
                </Box>
              </MenuItem>
            ))}
          </Stack>
        )}
        {!isLoading && notifications?.length === 0 && (
          <Box className=" flex justify-center items-center py-5 h-[450px]">
            <Typography className=" text-center ">No Recent Notifications</Typography>
          </Box>
        )}
      </MenuList>
      <Box className="d-flex justify-content-end p-2">
        <Button
          size="small"
          className="px-2 py-1 text-sm"
          variant="text"
          color="primary"
          onClick={handleGetMoreData}
          disabled={disable}
        >
          Read More
        </Button>
      </Box>
    </Menu>
  );
}

NotificationsMenu.propTypes = {
  anchorEl: propTypes.object,
  notifications: propTypes.array,
  isLoading: propTypes.bool,
  disable: propTypes.bool,
  handleClose: propTypes.func.isRequired,
  handleReadAll: propTypes.func.isRequired,
  handleSetNotifications: propTypes.func.isRequired,
  handleGetMoreData: propTypes.func.isRequired,
};
NotificationsMenu.defaultProps = {
  anchorEl: null,
  notifications: [],
  isLoading: false,
};
export default NotificationsMenu;
