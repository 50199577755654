import SuccessSnackbar from 'containers/common/components/SuccessSnackbar';

import { jwtDecode } from 'jwt-decode';
import moment from 'moment';
import axios from 'axios';

import { API_URL } from 'utilities/constants';
// IMAGES
import blankFile from 'assets/file_simple.svg';
import pdfFile from 'assets/file-pdf.svg';
import docFile from 'assets/file-doc.svg';
import cssFile from 'assets/file-css.svg';
import imgFile from 'assets/file-image.svg';
import sqlFile from 'assets/file-SQL.svg';
import excelFile from 'assets/excel.svg';
import aiFile from 'assets/file-AI.svg';

export const snackbarProviderProps = {
  preventDuplicate: true,
  anchorOrigin: { horizontal: 'center', vertical: 'bottom' },
  Components: { success: SuccessSnackbar },
};

/* THIS HELPER WILL RETURN PRICE AND CURRENCY (DEFAULT CURRENCY IS AED) */
export const getParsedPrice = (price = 0.0) => {
  if (price) {
    return Number(price?.toFixed(0));
  }

  return 'On Request';
};

/* THIS HELPER WILL TRANSFORM SEARCH PARAMS OBJECT INTO JAVASCRIPT PLAIN OBJECT */
export const getSearchParamsObj = searchParams => Object.fromEntries(searchParams);

export const getRandomImage = (images = []) => {
  const randomIndex = Math.floor(Math.random() * images.length);

  return images[randomIndex];
};

/* THIS HELPER WILL HELP US TRANSFORM THE SESSION STORAGE SEARCH PAYLOAD INTO SEARCH PARAMS */
export const getTransformedSearchParams = (
  search,
  isHotelList = false,
  isDirectSearch = false
) => {
  if (!search) {
    return {};
  }

  const guestCount = search.rooms[0].adults + search.rooms[0].childrens;
  const maxChildAge = () => {
    if (
      !search.rooms ||
      search.rooms.length === 0 ||
      !search.rooms[0].child_ages ||
      search.rooms[0].child_ages.length === 0
    ) {
      return undefined; // or any other appropriate value indicating no child ages found
    }
    return Math.max(...search.rooms[0].child_ages);
  };

  const params = {
    start_date: search?.start_date,
    end_date: search?.end_date,
    room_quantity: search?.rooms.length || undefined,
    guest_quantity: guestCount || undefined,
    max_child_age: maxChildAge() || undefined,
    child_count: search.rooms[0].childrens || undefined,
    adult_count: search.rooms[0].adults || undefined,

    ...(isDirectSearch && {
      direct_search: isDirectSearch,
      hotel_name: search?.hotel_name || undefined,
    }),

    ...(isHotelList && {
      hotel_city_code: search?.search || undefined,
    }),
  };

  return params;
};
// NOTIFICATION HELPER FUNCTIONS
export const findUnreadNotificationCount = notifications => {
  if (notifications?.length > 0) return notifications[0]?.unread_notification_count;
  return 0;
};
// HELPER Function to format date
export const formatDate = (date, customFormat) => moment(date).format(customFormat || 'DD MM YYYY');

// HELPER Function to get total adults and childrens
export function getTotalAdultsAndChildren(rooms) {
  let totalAdultsCount = 0;
  let totalChildrenCount = 0;

  rooms?.forEach(room => {
    totalAdultsCount += room.adults;
    totalChildrenCount += room.childrens;
  });

  return [totalAdultsCount, totalChildrenCount];
}

export const formatCancelationDate = dateString => moment(dateString).format('MMM D, YYYY, hh:mm A');

export const formatAfterCancelationDate = dateString => moment(dateString).add(1, 'minute').format('MMM D, YYYY, hh:mm A');

export const getCardNumberLastDigits = cardNumber => {
  // Remove spaces from the string
  const stringWithoutSpaces = cardNumber.replace(/\s/g, '');

  // Get the last 4 characters
  const lastFourDigits = stringWithoutSpaces.slice(-4);

  return `•••• ${lastFourDigits}`;
};

export const createCountrySelectOptions = arr => arr?.map(item => ({ label: item, value: item }));

export const formatExpiryDate = dateString => moment(dateString).format('DD/YY');

export const getCreditCardAbbreviation = cardType => {
  const lowerCaseCardType = cardType?.toLowerCase();
  // Check if cardType contains certain keywords
  switch (true) {
    case lowerCaseCardType.includes('master'):
      return 'CA';
    case lowerCaseCardType.includes('visa'):
      return 'VI';
    case lowerCaseCardType.includes('american express'):
      return 'AX';
    case lowerCaseCardType.includes('diners'):
      return 'DC';
    case lowerCaseCardType.includes('discover'):
      return 'DS';
    case lowerCaseCardType.includes('japan credit bureau'):
      return 'JC';
    case lowerCaseCardType.includes('universal air travel plan'):
      return 'TP';
    case lowerCaseCardType.includes('unionpay'):
      return 'UP';
    default:
      return 'Unknown'; // or throw an error, depending on requirements
  }
};

// Generate select options data
export const transformSelectOptions = (array, label, value) => {
  if (array && array?.length > 0) {
    const optionsData = array?.map(item => ({
      label: item[label],
      value: item[value],
    }));
    return optionsData;
  }

  return [];
};
// Get label from options data using value
export const getLabelFromValueId = (array, value) => {
  if (!array || array?.length === 0) {
    return '';
  }
  const foundItem = array.find(item => item.value === value);
  return foundItem ? foundItem.label : '';
};

export const capitalizeWords = str => {
  let words = str?.split(' ');
  words = words?.map(word => word.charAt(0).toUpperCase() + word.slice(1));
  return words?.join(' ');
};

export const bookingIdStringJoin = arr => {
  if (typeof arr === 'string') {
    return arr; // return the single element as a string
  }
  return arr?.join(' , '); // join all elements with ' , '
};

export const getCancellationPolicyData = data => {
  const cancellationAmount = data?.room_cancellation?.[0]?.cancellation_amount || null;
  const noOfNights = data?.room_cancellation?.[0]?.number_of_nights || null;
  const percentage = data?.room_cancellation?.[0]?.percentage || null;

  return {
    cancellation_amount: cancellationAmount,
    no_of_nights: noOfNights,
    percentage,
  };
};

export const scrollToTop = () => window.scrollTo(0, 0);

export const formatName = (firstName, lastName, userName) => (!!firstName && !!lastName ? `${firstName} ${lastName[0]}` : userName);
export const getSorting = (order, orderBy) => {
  if (order === 'asc') {
    return (a, b) => {
      if (Number(a[orderBy]) < Number(b[orderBy])) {
        return -1;
      }
      if (Number(a[orderBy]) > Number(b[orderBy])) {
        return 1;
      }
      return 0;
    };
  }
  return (a, b) => {
    if (Number(a[orderBy]) > Number(b[orderBy])) {
      return -1;
    }
    if (Number(a[orderBy]) < Number(b[orderBy])) {
      return 1;
    }
    return 0;
  };
};

export const getDateSorting = (order, orderBy) => {
  if (order === 'asc') {
    return (a, b) => {
      if (a[orderBy] < b[orderBy]) {
        return -1;
      }
      if (a[orderBy] > b[orderBy]) {
        return 1;
      }
      return 0;
    };
  }
  return (a, b) => {
    if (a[orderBy] > b[orderBy]) {
      return -1;
    }
    if (a[orderBy] < b[orderBy]) {
      return 1;
    }
    return 0;
  };
};
export const setIconByFileType = type => {
  if (type.includes('pdf')) return pdfFile;
  if (type.includes('css')) return cssFile;
  if (
    type.includes('image') ||
    type.includes('png') ||
    type.includes('jpg') ||
    type.includes('jpeg') ||
    type.includes('svg')
  ) {
    return imgFile;
  }
  if (type.includes('ai')) return aiFile;
  if (type.includes('sql')) return sqlFile;
  if (
    type.includes('xls') ||
    type.includes('xlsx') ||
    type.includes('csv') ||
    type.includes('ms-excel') ||
    type.includes('spreadsheet')
  ) {
    return excelFile;
  }
  if (type.includes('doc')) return docFile;

  return blankFile;
};

export const formatFileSize = (sizeInBytes = 0) => {
  const decimal = 1;
  const marker = 1024;

  const toKB = (+sizeInBytes / marker).toFixed(decimal);
  const toMB = (+sizeInBytes / (marker * marker)).toFixed(decimal);
  const toGB = (+sizeInBytes / (marker * marker * marker)).toFixed(decimal);

  if (toKB < marker) return `${toKB} kb`;
  if (toMB < marker) return `${toMB} mb`;
  if (toGB < marker) return `${toGB} gb`;

  return +sizeInBytes;
};

export const toTitleCase = str => {
  if (!str || !str?.includes('_')) return str;
  return str
    ?.split('_') // Split the string by underscores
    .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize the first letter of each word
    .join(' ');
}; // Join the words with a space

export const isTokenExpired = token => {
  if (!token) return true;
  try {
    const decodedToken = jwtDecode(token);
    const currentTime = Date.now() / 1000;
    return decodedToken.exp < currentTime;
  } catch (error) {
    console.error('Error decoding token:', error);
    return true;
  }
};

export const axiosAPiCall = async (endpoint, payload) => {
  const token = localStorage.getItem('token');
  const apiEndPoint = API_URL + endpoint;
  const response = await axios.post(apiEndPoint, payload, {
    headers: {
      Authorization: `JWT ${token}`,
    },
  });
  return response;
};
