import newsletterBgImg from 'assets/home-banner.jpeg';

export const heroSectionContainerStyles = bannerImg => ({
  background: `linear-gradient(0deg, rgba(0,0,0,0), rgba(0,0,0,0)), url(${bannerImg}) no-repeat`,
  backgroundPosition: 'right 0px',
  backgroundSize: '100% 100%',
  height: 'calc(100vh - 95px)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  '& .MuiFormHelperText-root.Mui-error': {
    color: 'white',
  },
});

export const heroSectionSplashScreenContainerStyles = (
  bannerImg,
  isHeightProvided = false
) => ({
  background: `linear-gradient(0deg, rgba(0,0,0,0.6), rgba(0,0,0,0.6)), url(${bannerImg}) center/cover no-repeat`,
  minHeight: '200px',
  height: isHeightProvided || 'calc(100vh - 95px)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  '& .MuiFormHelperText-root.Mui-error': {
    color: 'white',
  },
  '@media (max-width: 780px)': {
    height: isHeightProvided || '60vh',
    minHeight: '300px',
  },
});

export const heroSectionTextContainerStyles = {
  background: 'rgba(255, 255, 255, 0.6)',
  borderRadius: '8px',
};

export const searchFormContainerStyle = {
  // border: '2px solid #E7E7E7',
  // background: 'rgba(255, 255, 255, 1) !important',
  background: '#EAEAEA',
  padding: '30px 15px',
  borderRadius: '13px',
  marginTop: '20px',
  boxShadow: ' rgba(0, 0, 0, 0.1) 0px 4px 12px'
  // boxShadow: '0px 4px 16px 0px #00000040',
  // boxShadow: 'rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px',
};

export const bookingOptionsContainerStyle = {
  // background: 'rgba(255, 255, 255, 1) !important',
  background: '#EAEAEA',
  // boxShadow: 'rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px',
  // boxShadow: '0px 4px 8.3px 0px #00000012',
  boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
  zIndex: 1,
  position: 'relative',
  padding: '0px 2px',
  marginBottom: '-22px',
  maxWidth: '700px',
  marginX: 'auto',
  borderRadius: '4px',
  marginTop: '3px',
  // hari changings
};

export const bookingOptionsTabsStyle = {
  '& .MuiTabs-flexContainer': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap',
    textTransform: 'none',
  },
};

export const bookingOptionsIconsStyle = { width: '28px', height: '28px' };

export const bookingsTabStyle = {
  textTransform: 'none',
  color: '#000000',
  fontSize: '16px',
};

export const gridPaneImageStyles = {
  background:
    'url(https://swiperjs.com/demos/images/nature-8.jpg) center/cover no-repeat',
  maxHeight: '600px',
  minHeight: '600px',
  height: '100%',
  width: '100%',

  '@media screen and (max-width: 768px)': {
    minHeight: '400px',
  },
};

export const iconWaterMarkStyles = {
  position: 'absolute',
  top: 0,
  right: 0,
  transform: 'translateX(30%) rotate(-90deg)',
  opacity: 0.04,

  '& img': {
    height: '400px',
  },
};

export const newsletterSectionStyles = {
  background: `linear-gradient(90deg, rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url(${newsletterBgImg}) center/cover no-repeat fixed`,
};
