import React, { useCallback } from 'react';
// MUI COMPONENTS && ICONS
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import styles from 'styles/common/layout.module.scss';
import logoImg from 'assets/logo.png';
import { footerContactLinksData, footerLinksData } from 'utilities/data';
import useAuth from 'customHooks/useAuth';
import { Link, useNavigate } from 'react-router-dom';
import NavColumn from './NavColumn';

function Footer() {
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();

  const handleNavigate = useCallback((path, newTab = false) => {
    if (newTab) window.open(path, '_blank');
    else navigate(path);
  }, []);
  return (
    <Box className={styles.footerContainer}>
      <Box className="px-3 px-lg-5">
        <Grid container justifyContent="space-between" alignItems="start" rowGap={4}>
          <Grid item xs={12} sm={12} md={6} className="d-flex flex-column">
            <Box>
              <img className="mb-3" src={logoImg} alt="Logo" />
            </Box>
            <Typography variant="body1">
              Experience luxury living, where sophistication meets serenity!
            </Typography>
          </Grid>
          <Grid item xs={6} sm={6} md={4} className="text-start" pt={{ md: 3 }}>
            <NavColumn label="Contact" links={footerContactLinksData} isRTL />
          </Grid>
          <Grid item xs={12}>
            <Divider style={{ background: 'white' }} />
          </Grid>
          <Grid item xs={12} className="d-flex gap-2 justify-content-between flex-wrap">
            <Typography variant="body2" className="text-center">
              Copyright &#169; 2025{' '}
              <Link to="/home" style={{ textDecoration: 'none', color: 'inherit' }}>
                Sublime
              </Link>
              . All rights reserved.
            </Typography>
            <Stack
              spacing={1}
              direction="row"
              divider={(
                <Divider
                  orientation="vertical"
                  flexItem
                  style={{ background: 'white' }}
                />
              )}
            >
              {footerLinksData
                .filter(link => !link.isAuth || (link.isAuth && !isAuthenticated))
                .map(link => (
                  <Box key={link?.id} className="d-flex gap-2 text-break">
                    {link?.icon && link.icon}
                    <Typography
                      variant="body2"
                      key={link.label}
                      className={link?.path ? 'pointer' : ''}
                      onClick={
                        link.path
                          ? () => handleNavigate(link.path, link?.newTab)
                          : undefined
                      }
                    >
                      {link.label}
                    </Typography>
                  </Box>
                ))}
            </Stack>
            <Typography variant="body2" color="primary" className="text-center">
              Powered by - Beyond Eris Solutions
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default Footer;
